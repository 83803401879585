import React from 'react';

import { isThereContent } from '../utilties/helpers';

const Content = ({ content, full, boxed }) => {
    let contentComponent;

    if (isThereContent(content)) {
        if (full) {
            contentComponent = (<div className="o-content" dangerouslySetInnerHTML={{ __html: content }}></div>);
        } else if (boxed) {
            contentComponent = (
                <div className="o-content o-block--bordered xs-mb-30">
                    <div className="o-block o-block--span-full">
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                </div>
            );
        } else {
            contentComponent = (
                <div className="o-content xs-mt-20 md-mt-40 lg-mt-60 xs-mb-20 lg-mb-40 container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return contentComponent ? contentComponent : null;
};

export default Content;