import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import FAQBanner from '../components/contact/component.faq';
import CallUs from '../components/contact/component.callus';
import ContactForm from "../components/contact/component.contact-form";
import ComponentParser from '../components/component.parser';
import Content from '../components/component.content';
import CookieNotice from "../components/component.cookie-notice";

const Contact = ({ data }) => {

    const { title, content, blocks, featuredImage, seo, language, translations, slug } = data.wordpress.page;
    const bannerImage = featuredImage ? featuredImage.node.imageFile : false;

    let contactContent;
     // language is taken from the page's language code
    if(typeof data.wordpress[language.code] !== "undefined") {
        contactContent = data.wordpress[language.code].ACFContactBlockFields;
    } else {
        contactContent = data.wordpress['EN'].ACFContactBlockFields;
    }

    return (
        <Layout language={language} translations={translations}>
            <SEO title={title} seo={seo} image={featuredImage} translations={translations} slug={slug} language={language} />
            <HeroBanner title={title} image={bannerImage} />

            <div className="u-bg--seashell xs-pt-40 xs-pb-40">
                <div className="container">
                    {content &&
                        <Content content={content} boxed />
                    }

                    {blocks &&
                        <ComponentParser blocks={blocks} />
                    }

                    <FAQBanner title={contactContent.faqTitle} subtitle={contactContent.faqSubtitle} linkLabel={contactContent.faqLinkLabel} linkUrl={contactContent.faqLinkUrl.url} />
                    <CallUs title={contactContent.callUsTitle} subtitle={contactContent.callUsSubtitle} />
                    <ContactForm />
                </div>
            </div>
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                slug
                content
                language {
                    code
                    locale
                }
                translations {
                    uri
                    language {
                        code
                        locale
                    }
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                seo {
                    ...SeoPostFields
                }
                ...PageBlocks
            }
            EN: globalContent(idType: SLUG, id: "contact-en") {
                ...getContactContent
            }
            ES: globalContent(idType: SLUG, id: "contact-es") {
                ...getContactContent
            }
            FR: globalContent(idType: SLUG, id: "contact-fr") {
                ...getContactContent
            }
            ZH: globalContent(idType: SLUG, id: "contact-zh") {
                ...getContactContent
            }
            DE: globalContent(idType: SLUG, id: "contact-de") {
                ...getContactContent
            }
            PL: globalContent(idType: SLUG, id: "contact-pl") {
                ...getContactContent
            }
        }
    }
`;

export default Contact;
