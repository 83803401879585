import React from 'react';

import Button from '../component.button';

const FAQBanner = ({ title, subtitle, linkLabel, linkUrl }) => (
    <div className="c-faq-banner o-block--bordered">
        <div className="o-block o-block--span-full xs-mb-20 lg-mb-0">
            <h3 className="c-faq-banner__title">{title}</h3>
            <p className="c-faq-banner__subtitle">{subtitle}</p>
        </div>
        <div className="o-block o-block--align-right o-block--align-middle">
            <Button link to={linkUrl} text={linkLabel} />
        </div>
    </div>
);

export default FAQBanner;
