import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';

const Dropdown = ({ title, label, list, callback, additionalClassNames, identifier, active }) => {
    const classNames = `c-dropdown ${additionalClassNames}`;
    const sectionId = `${identifier}Filter`;
    const [open, isOpen] = useState(false);
    const [selectedItem, isSelectedItem] = useState(false);
    const [currentLabel, setCurrentLabel] = useState(label);
    const dropdownEl = useRef(null);

    useEffect(() => {
        // If active is passed update the current label to show this
        if (active) {
            setCurrentLabel(active);
        }

        // If dropdown is open add a listner for external clicks to trigger close
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const handleClickOutside = (e) => {
        if (dropdownEl.current.contains(e.target)) {
            // Click inside dropdown
            return false;
        }

        isOpen(false);
    };

    /**
     * Update selected item and close the menu
     *
     * @param {*} item
     */
    function selectItem({ name, id }) {
        // Update selected item
        isSelectedItem(name);

        // Use callback if supplied
        if (typeof callback === 'function') {
            callback(id);
        }

        // Update label
        setCurrentLabel(name);

        // Close menu
        isOpen(false);
    }

    return (
        <div ref={dropdownEl} className={classNames}>
            {title &&
                <span className="c-dropdown__title" id={sectionId}>{`${title}:`}</span>
            }

            <div className="c-dropdown__list-container">
                <div
                    className="c-dropdown__label"
                    onClick={() => isOpen(!open)}
                >
                    {currentLabel} <i className={open ? "c-dropdown__icon fal fa-angle-up" : "c-dropdown__icon fal fa-angle-down"}></i>
                </div>

                <ul
                    className={open ? "c-dropdown__list c-dropdown__list--active" : "c-dropdown__list"}
                    aria-hidden={open ? false : true}
                    aria-labelledby={sectionId}
                >
                    {list.map(item => {
                        if (item.path) {
                            return (
                                <li
                                    className={selectedItem === item.name ? "c-dropdown__item c-dropdown__item--active" : "c-dropdown__item"}
                                    key={item.id}
                                >
                                    <Link
                                        to={item.path}
                                        className="c-dropdown__item-link"
                                        activeClassName="c-dropdown__item-link--active"
                                        state={{ selectedItem: item }}
                                    >
                                        {item.name}
                                    </Link>
                                </li>
                            );
                        } else {
                            return (
                                <li
                                    className={selectedItem === item.name ? "c-dropdown__item c-dropdown__item--active" : "c-dropdown__item"}
                                    key={item.id}
                                    onClick={() => selectItem(item)}
                                >
                                    {item.name}
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Dropdown;
