import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import useCountriesData from "../../hooks/hook.useCountriesData";
import useFetchPost from "../../hooks/hook.useFetchPost";
import Button from '../component.button';
import addToMailchimp from "gatsby-plugin-mailchimp";

const ContactForm = ({ t }) => {
    const countries = useCountriesData();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const defaultContactDetails = {
        subject: '',
        country: '',
        phoneNumber: '',
        contactName: '',
        club: '',
        email: '',
        message: '',
        newsletter: false,
    }
    const [contactDetails, setContactDetails] = useState(defaultContactDetails);

    const handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        // handle checkbox
        if (name === 'newsletter') {
            value = target.checked;
        }

        setContactDetails({
            ...contactDetails,
            [name]: value,
        });
    };

    const subjects = [
        t("contact.contact-form.sales-subject"),
        t("contact.contact-form.service-subject"),
        t("contact.contact-form.marketing-subject"),
        t("contact.contact-form.other-subject"),
    ];

    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/5/feedback',
        data: contactDetails,
        submitClick: formSubmitted
    });

    const handleSubmit = async event => {
        event.preventDefault();

        // add the selected class/hull button selections
        setContactDetails({
            ...contactDetails,
        });

        if (contactDetails.newsletter) {
            addToMailchimp(contactDetails.email, { 'PAGEID': 'Contact' });
        }

        // submit the form
        await setFormSubmitted(true);

        // revert the form submitted to default state
        await setFormSubmitted(false);
    };

    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setContactDetails(defaultContactDetails);
        }
    }, [formSubmitted, formSubmission]);

    return (
        <div className="c-form c-contact-form o-block--bordered xs-mt-30 lg-pl-0 lg-pr-0">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <form className="c-form" onSubmit={handleSubmit}>
                        <div className="c-contact-form__header">
                            <div className="c-contact-form__title">
                                {t("contact.contact-form.title")}
                            </div>

                            <div className="c-contact-form__description">
                                {t("contact.contact-form.description")}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="contactCountry"
                                            name="country"
                                            value={contactDetails.country}
                                            onChange={handleInputChange}
                                            required="true"
                                        >
                                            <option value=''>{t("contact.contact-form.country-option")}</option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country.ACFDealerBlockFields.country}>{country.ACFDealerBlockFields.country}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            value={contactDetails.subject}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option>{t("contact.contact-form.subject-option")}</option>
                                            {subjects.map((subject, index) => (
                                                <option key={index}>{subject}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <input
                                        className={contactDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                        type="text"
                                        id="contactName"
                                        name="contactName"
                                        value={contactDetails.contactName}
                                        onChange={handleInputChange}
                                    />
                                    <label className="c-input-holder__label" htmlFor="contactName">{t("contact.contact-form.name-label")} <span>({t("contact.contact-form.optional-span")})</span></label>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <input
                                        className={contactDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                        type="text"
                                        id="contactEmail"
                                        name="email"
                                        value={contactDetails.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className="c-input-holder__label" htmlFor="contactEmail">{t("contact.contact-form.email-label")}</label>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <input
                                        className={contactDetails.phoneNumber.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                        type="number"
                                        id="contactPhoneNumber"
                                        name="phoneNumber"
                                        value={contactDetails.phoneNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className="c-input-holder__label" htmlFor="contactPhoneNumber">{t("contact.contact-form.phone-label")}</label>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <input
                                        className={contactDetails.club.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                        type="text"
                                        id="club"
                                        name="club"
                                        value={contactDetails.club}
                                        onChange={handleInputChange}
                                    />
                                    <label className="c-input-holder__label" htmlFor="club">{t("contact.contact-form.club-label")} <span>({t("contact.contact-form.optional-span")})</span></label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="c-input-holder">
                                    <textarea
                                        className={contactDetails.message.length ? "c-input-holder__textarea c-input-holder__textarea--has-content" : "c-input-holder__textarea"}
                                        type="text"
                                        id="contactMessage"
                                        name="message"
                                        value={contactDetails.message}
                                        onChange={handleInputChange}
                                    />
                                    <label className="c-input-holder__label" htmlFor="contactMessage">{t("contact.contact-form.message-label")} <span>({t("contact.contact-form.optional-span")})</span></label>
                                </div>
                            </div>
                        </div>

                        <div className="c-contact-form__footer">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="c-input-holder__checkbox-wrapper xs-mb-20 md-mb-0">
                                        <div>
                                            <input
                                                className="c-input-holder__checkbox"
                                                type="checkbox"
                                                name="newsletter"
                                                id="newsletter"
                                                value={contactDetails.newsletter}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <label className="c-input-holder__label--checkbox" htmlFor="newsletter">{t("contact.contact-form.tick-label")}</label>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="c-contact-form__submit">
                                        <Button text={t("contact.contact-form.button-label")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ContactForm);
